import * as Settings from '../settings';
import FigureColors from './FigureColors';

    // abstract class to represent a figure.
    //
export default class Figure {
    constructor(gameState, finishHandler, updateStateHandler) {

        this.gameState = gameState;
        this.finishHandler = finishHandler;
        this.updateStateHandler = updateStateHandler;

        this.id = 0;
        this.colorOfFigure = FigureColors.White;
        this.positionOfFigure = [];
    }

    // changes a state code of figure when rotation is being made.
    // implements pattern Template method.
    //
    changeCurrentState() { }

    // get new figure position for possible rotation.
    // implements pattern Template method.
    //
    getFigureCandidate() { }


    //
    rotation() {
        this.remove();

        this.positionOfFigure = this.getFigureCandidate();
        this.changeCurrentState();

        this.draw();
    }


    //
    isRotationPossible() {
        const figureCandidate = this.getFigureCandidate();

        for (let element of figureCandidate) {
            let { x, y, cellId } = element;

            if (this.doesFigureContainLocation(x, y) === true) {
                continue;
            }

            if (this.isPointInsideGamePanel(x, y) === false) {
                return false;
            }

            if (this.gameState.getElement(cellId).color !== FigureColors.White) {
                return false;
            }

        }

        return true;
    }

    //
    isPointInsideGamePanel(x, y) {
        if (x < 0) {
            return false;
        }

        if (x >= Settings.Width.getWidth()) {
            return false;
        }

        if (y < 0) {
            return false;
        }

        if (y >= Settings.Height.getHeight()) {
            return false;
        }

        return true;
    }



    // state when new figure may not be added to game field is a state of game finish.
    //
    checkForGameFinish() {

        for (let element of this.positionOfFigure) {
            let { cellId } = element;

            if (this.gameState.getElement(cellId).color !== FigureColors.White) {
                this.finishHandler();

                this.drawPartsOfFigureWhichFitInGameField();

                return true;
            }
        }

        return false;
    }

    //
    drawPartsOfFigureWhichFitInGameField() {
        for (let element of this.positionOfFigure) {
            let { x, y, cellId } = element;

            if (this.gameState.getElement(cellId).color !== FigureColors.White) {
                continue;
            }

            this.gameState.setElement(x, y, this.colorOfFigure, cellId);
        }

        this.updateStateHandler(this.gameState.getMatrix());
    }

    //
    draw() {

        for (let element of this.positionOfFigure) {
            let { x, y, cellId } = element;

            this.gameState.setElement(x, y, this.colorOfFigure, cellId);
        }

        this.updateStateHandler(this.gameState.getMatrix());
    }

    //
    remove() {
        for (let element of this.positionOfFigure) {
            let { x, y, cellId } = element;

            this.gameState.setElement(x, y, FigureColors.White, cellId);
        }

        this.updateStateHandler(this.gameState.getMatrix());
    }

    //
    moveToLeft() {
        this.remove();

        let newPositionOfFigure = [];

        for (let element of this.positionOfFigure) {
            let { x, y } = element;

            let newX = x - 1;

            let item = {
                x: newX,
                y: y,
                color: this.colorOfFigure,
                cellId: `${newX}.${y}`
            }

            newPositionOfFigure.push(item);
        }

        this.positionOfFigure = newPositionOfFigure;

        this.draw();
    }

    //
    moveToRight() {
        this.remove();

        let newPositionOfFigure = [];

        for (let element of this.positionOfFigure) {
            let { x, y } = element;

            let newX = x + 1;

            let item = {
                x: newX,
                y: y,
                color: this.colorOfFigure,
                cellId: `${newX}.${y}`
            }

            newPositionOfFigure.push(item);
        }

        this.positionOfFigure = newPositionOfFigure;

        this.draw();
    }

    //
    moveDown() {
        this.remove();

        let newPositionOfFigure = [];

        for (let element of this.positionOfFigure) {
            let { x, y } = element;

            let newY = y + 1;

            let item = {
                x: x,
                y: newY,
                color: this.colorOfFigure,
                cellId: `${x}.${newY}`
            }

            newPositionOfFigure.push(item);
        }

        this.positionOfFigure = newPositionOfFigure;

        this.draw();
    }

    //
    isMoveToLeftPossible() {
        if (this.leftBoundaryOfFigure() === 0) {
            return false;
        }

        for (let element of this.positionOfFigure) {
            let { x, y } = element;

            let newX = x - 1;

            if (this.doesFigureContainLocation(newX, y) === true) {
                continue;
            }

            const cellId = `${newX}.${y}`;

            if (this.gameState.getElement(cellId).color !== FigureColors.White) {
                return false;
            }
        }

        return true;
    }

    //
    isMoveToRightPossible() {
        if (this.rightBoundaryOfFigure() === Settings.Width.getWidth() - 1) {
            return false;
        }

        for (let element of this.positionOfFigure) {
            let { x, y } = element;

            let newX = x + 1;

            if (this.doesFigureContainLocation(newX, y) === true) {
                continue;
            }

            const cellId = `${newX}.${y}`;

            if (this.gameState.getElement(cellId).color !== FigureColors.White) {
                return false;
            }
        }

        return true;
    }

    //
    isMoveDownPossible() {
        if (this.bottomBoundaryOfFigure() === Settings.Height.getHeight() - 1) {
            return false;
        }

        for (let element of this.positionOfFigure) {
            let { x, y } = element;

            let newY = y + 1;

            if (this.doesFigureContainLocation(x, newY) === true) {
                continue;
            }

            const cellId = `${x}.${newY}`;

            if (this.gameState.getElement(cellId).color !== FigureColors.White) {

                return false;
            }
        }

        return true;
    }


    //
    doesFigureContainLocation(x, y) {
        const result =
            this.positionOfFigure.find(element => element.x === x && element.y === y);

        if (!result) {
            return false;
        }

        return true;
    }

    //
    leftBoundaryOfFigure() {
        let leftBoundary = Settings.Width.getWidth();

        for (let element of this.positionOfFigure) {
            let { x } = element;

            if (x < leftBoundary) {
                leftBoundary = x;
            }
        }

        return leftBoundary;
    }

    rightBoundaryOfFigure() {
        let rightBoundary = 0;

        for (let element of this.positionOfFigure) {
            let { x } = element;

            if (x > rightBoundary) {
                rightBoundary = x;
            }
        }

        return rightBoundary;
    }

    bottomBoundaryOfFigure() {
        let bottomBoundary = 0;

        for (let element of this.positionOfFigure) {
            let { y } = element;

            if (y > bottomBoundary) {
                bottomBoundary = y;
            }
        }

        return bottomBoundary;
    }

    createElement(x, y) {

        let item = {
            x: parseInt(x),
            y: parseInt(y),
            color: this.colorOfFigure,
            cellId: `${parseInt(x)}.${parseInt(y)}`,
        };

        return item;
    }

}
