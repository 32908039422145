import FigureId from './FigureId';
import FigureSquare from './FigureSquare';
import FigureLeftCorner from './FigureLeftCorner';
import FigureLeftStep from './FigureLeftStep';
import FigureLine from './FigureLine';
import FigurePyramid from './FigurePyramid';
import FigureRightCorner from './FigureRightCorner';
import FigureRightStep from './FigureRightStep';

// implements pattern Factory.
//
export class FigureFactory {

    //
    getRandomFigureId() {

        const figureId = Math.floor(Math.random() * 7) + 1;
        return figureId;
    }

    // implements pattern Factory method.
    //
    createFigureFromFigureId(figureId, gameState, finishHandler, updateStateHandler) {
        let figure = null;

        switch (figureId) {
            case FigureId.FigureLeftCorner:

                figure = new FigureLeftCorner(gameState, finishHandler, updateStateHandler);
                break;

            case FigureId.FigureLeftStep:

                figure = new FigureLeftStep(gameState, finishHandler, updateStateHandler);
                break;

            case FigureId.FigureLine:

                figure = new FigureLine(gameState, finishHandler, updateStateHandler);
                break;

            case FigureId.FigurePyramid:

                figure = new FigurePyramid(gameState, finishHandler, updateStateHandler);
                break;

            case FigureId.FigureRightCorner:

                figure = new FigureRightCorner(gameState, finishHandler, updateStateHandler);
                break;
            
            case FigureId.FigureRightStep:

                figure = new FigureRightStep(gameState, finishHandler, updateStateHandler);
                break;
                
            case FigureId.FigureSquare:

                figure = new FigureSquare(gameState, finishHandler, updateStateHandler);
                break;

            default:
                console.log(`FigureFactory.createFigureFromFigureId(figureId): wrong figureId ${figureId}`);
                break;
        }

        return figure;
    }
}

