export const SquareSize = 24;

export const Height =
{
    value1: '15',

    getHeight() { return this.value1; },
    setHeight(value) { this.value1 = value; }
}

export const Width =
{
    value1: '10',

    getWidth() { return this.value1; },
    setWidth(value) { this.value1 = value; }
}

export const Speed =
{
    value1: '750',

    getSpeed() { return this.value1; },
    setSpeed(value) { this.value1 = value; }
}
