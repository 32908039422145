
import React, { Component } from 'react';

class ScoreTable extends Component {

    render() {

        const _style = {

            fontSize: "64px",
            textAlign: "center",
            textAlignVertical: 'center'
        };

        return <div style={_style}>{this.props.counter}</div>;
    }

}

export default ScoreTable;

export class Score {

    constructor() {
        this.counter = 0;
    }

    reset() {
        this.counter = 0;
    }

    increment(number) {
        this.counter += number;
    }

    getScore() {
        return this.counter;
    }

}