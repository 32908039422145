import React, { Component } from 'react';
import { SquareFactory } from '../square.js';
import FigureColors from '../Figure/FigureColors';
import NextFigureFactory from '../NextFigure/NextFigureFactory';
import { NextFigureState } from './NextFigureState';

//
class NextFigure extends Component {

    constructor(props) {

        super(props);

        this.size = 6;

        this.nextFigureFactory = new NextFigureFactory();
        this.nextFigureState = new NextFigureState(this.size, this.size);

        let figure = this.nextFigureFactory.create(props.nextFigureId);
        this.createNextFigure(figure);

        this.state =
        {
            nextFigureField: this.nextFigureState.getMatrix()
        }

        this.setState({});
       }

    //
    createNextFigure = (nextFigure) => {

        let positions = nextFigure.getPositionOfFigure();

        for (let y = 0; y < this.size; y++) {

            for (let x = 0; x < this.size; x++) {

                let color = FigureColors.White;

                if (positions.findIndex(element => element.x === x && element.y === y) !== -1) {

                    color = nextFigure.color;
                }
             
                let cellId = `next.${parseInt(x)}.${parseInt(y)}`;

                this.nextFigureState.setElement(x, y, color, cellId);
            }
        }
    }

    getRow(line) {

        return (<tr key={Math.random()}>
            {line.map(x => <td key={x.cellId}><SquareFactory key={x.cellId} color={x.color} cellId={x.cellId} /></td>)}
        </tr>);
    }

    render() {

        const map = Array.prototype.map;

        let nextFigureField =
            <table border="2" key={Math.random()}>
                <tbody>
                    {map.call(this.state.nextFigureField, line => this.getRow(line))}
                </tbody>
            </table>;

        return nextFigureField;
    }
}

export default NextFigure;
