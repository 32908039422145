import * as Settings from '../settings';
import FigureColors from './FigureColors';
import FigureId from './FigureId';
import Figure from './Figure';

const FigureRightCornerState = {

    State1: 0,
    State2: 1,
    State3: 2,
    State4: 3
}
Object.freeze(FigureRightCornerState);


export default class FigureRightCorner extends Figure {
    //   *
    //   *
    // * *  
    //
    constructor(gameState, finishHandler, updateStateHandler) {
        super(gameState, finishHandler, updateStateHandler);

        this.id = FigureId.FigureRightCorner;
        this.colorOfFigure = FigureColors.Green;
        this.currentState = FigureRightCornerState.State1;

        this.initFigure();
    }

    //
    initFigure() {
        const x = Settings.Width.getWidth() / 2;
        const y = 3;

        this.positionOfFigure = this.getState1(x, y);
        this.currentState = FigureRightCornerState.State1;

        this.checkForGameFinish();
    }

    //
    getState1(x, y) {
        let position = [];

        position.push(this.createElement(x, y - 3));
        position.push(this.createElement(x, y - 2));
        position.push(this.createElement(x, y - 1));
        position.push(this.createElement(x - 1, y - 1));

        return position;
    }

    //
    getState2(x, y) {
        let position = [];

        position.push(this.createElement(x + 1, y));
        position.push(this.createElement(x + 2, y));
        position.push(this.createElement(x + 3, y));
        position.push(this.createElement(x + 1, y - 1));

        return position;
    }

    //
    getState3(x, y) {
        let position = [];

        position.push(this.createElement(x + 1, y + 1));
        position.push(this.createElement(x, y + 1));
        position.push(this.createElement(x, y + 2));
        position.push(this.createElement(x, y + 3));

        return position;
    }

    //
    getState4(x, y) {
        let position = [];

        position.push(this.createElement(x, y - 2));
        position.push(this.createElement(x - 1, y - 2));
        position.push(this.createElement(x - 2, y - 2));
        position.push(this.createElement(x, y - 1));

        return position;
    }

    // changes a state code of figure when rotation is being made.
    // implements pattern Template method.
    //
    changeCurrentState() {
        switch (this.currentState) {
            case FigureRightCornerState.State1:

                this.currentState = FigureRightCornerState.State2;
                break;

            case FigureRightCornerState.State2:

                this.currentState = FigureRightCornerState.State3;
                break;

            case FigureRightCornerState.State3:

                this.currentState = FigureRightCornerState.State4;
                break;

            case FigureRightCornerState.State4:

                this.currentState = FigureRightCornerState.State1;
                break;

            default: break;
        }
    }

    // get new figure position for possible rotation.
    // implements pattern Template method.
    //
    getFigureCandidate() {
        let figureCandidate = [];

        const x = this.positionOfFigure[3].x;
        const y = this.positionOfFigure[3].y;


        switch (this.currentState) {
            case FigureRightCornerState.State1:

                figureCandidate = this.getState2(x, y);
                break;

            case FigureRightCornerState.State2:

                figureCandidate = this.getState3(x, y);
                break;

            case FigureRightCornerState.State3:

                figureCandidate = this.getState4(x, y);
                break;

            case FigureRightCornerState.State4:

                figureCandidate = this.getState1(x, y);
                break;

            default: break;
        }

        return figureCandidate;
    }
}
