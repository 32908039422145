const KeyCodes = {

    ArrowLeft: "ArrowLeft",
    ArrowUp: "ArrowUp",
    ArrowRight: "ArrowRight",
    ArrowDown: "ArrowDown",
    Space: " ",
    Pause: "p"
}
Object.freeze(KeyCodes);

//
export class FigureMovingByKeyboardControl {

    constructor(figureAutoMovingDownControl) {

        this.figure = null;
        this.figureAutoMovingDownControl = figureAutoMovingDownControl;
        this.pauseFlag = false;
    }

    // sets current figure to control.
    //
    setFigure(figure) {
        this.figure = figure;
    }

    //
    keyDown(key) {

        switch (key) {
            case KeyCodes.ArrowLeft:

                if (this.figure.isMoveToLeftPossible() === true) {
                    this.figure.moveToLeft();
                }

                break;

            case KeyCodes.ArrowRight:

                if (this.figure.isMoveToRightPossible() === true) {
                    this.figure.moveToRight();
                }

                break;

            case KeyCodes.ArrowDown:

                if (this.figure.isMoveDownPossible() === true) {
                    this.figure.moveDown();
                }

                break;

            case KeyCodes.ArrowUp:
            case KeyCodes.Space:

                if (this.figure.isRotationPossible() === true) {
                    this.figure.rotation();
                }

                break;

            case KeyCodes.Pause:

                this.pauseFlag = !this.pauseFlag;

                // add handler to stop moving down
                if (this.pauseFlag === false) {
                    
                    this.figureAutoMovingDownControl.pauseOff();
                }
                else {
                    this.figureAutoMovingDownControl.pauseOn();
                }
                break;

            default: break;
        }
    }

}
