import React, { Component } from 'react';
import GameField from './gameField.js';

class App extends Component {

    render = () => {

        return (
            <div>
                <GameField />
            </div>
        );
    }
}

export default App;
