import {Speed} from '../settings';

export class FigureAutoMovingDownControl {
    constructor() {

        this.timerInterval = parseInt(Speed.getSpeed());

        this.pause = false;

        this.timerId = null;

        this.figure = null;
        this.figureLandedEvent = null;
    }

    resetFigureLandedEvent() {
        this.figureLandedEvent = null;
    }

    resetTimer() {
        this.timerInterval = parseInt(Speed.getSpeed());
    }

    //
    start() {
        this.pause = false;
        this.timerTick();
    }


    //
    pauseOn() {
        this.pause = true;
    }

    //
    pauseOff() {        
        this.pause = false;
    }

    //
    finish() {
        
        if (this.timerId) {
            
            clearInterval(this.timerId);
        }
    }

    // sets current figure to move down.
    //
    setFigure(figure, figureLandedEvent) {

        this.figureLandedEvent = figureLandedEvent;

        this.figure = figure;

        if (this.figure.checkForGameFinish() === true) {
            return;
        }

        this.figure.draw();

        this.start();
    }

    //
    timerTick() {

        if (this.pause === true) {
            return;
        }

        //this.decrementTimerInterval();

        this.timerId = setInterval(() => {

            if (this.figure.isMoveDownPossible() === true) {

                if (this.pause === false) {
                    this.figure.moveDown()
                }
            }
            else {
                clearInterval(this.timerId);

                if (this.figureLandedEvent) {
                    this.figureLandedEvent();
                }
            }
        }, this.timerInterval);
    }

    //
    decrementTimerInterval() {
        if (this.timerInterval > 350) {
            this.timerInterval -= 5;
        }
    }

}
