import React, { Component } from 'react';
import * as Settings from '../settings';

export default class Options extends Component {

    componentDidMount() {

        Settings.Height.setHeight('15');
        Settings.Width.setWidth('10');
        Settings.Speed.setSpeed('750');
    }

    resetGameOptions() {

        const amountColumns = document.getElementById("amountColumns").value;
        Settings.Width.setWidth(amountColumns);

        const amountLines = document.getElementById("amountLines").value;
        Settings.Height.setHeight(amountLines);

        const speed = document.getElementById("speed").value;
        Settings.Speed.setSpeed(speed);
    }

    render() {

        const width1 = Settings.Width.getWidth();     
        const height1 = Settings.Height.getHeight();  
        const speed1 = Settings.Speed.getSpeed();     

        const _style= {
            borderWidth:  1,
            borderColor: "black",
            borderStyle: "solid"
        };

        const content = <form>
            <div >
                <table style={_style}>
                    <tr>
                        <td>Amount lines:</td>
                        <td>
                            <select name="amountLines" id="amountLines">
                                <option value="10" selected={height1==="10"}>10</option>
                                <option value="11" selected={height1==="11"}>11</option>
                                <option value="12" selected={height1==="12"}>12</option>
                                <option value="13" selected={height1==="13"}>13</option>
                                <option value="14" selected={height1==="14"}>14</option>
                                <option value="15" selected={height1==="15"}>15</option>
                                <option value="16" selected={height1==="16"}>16</option>
                                <option value="17" selected={height1==="17"}>17</option>
                                <option value="18" selected={height1==="18"}>18</option>
                                <option value="19" selected={height1==="19"}>19</option>
                                <option value="20" selected={height1==="20"}>20</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td>Amount columns:</td>
                        <td>
                            <select name="amountColumns" id="amountColumns">
                                <option value="10" selected={width1==='10'}>10</option>
                                <option value="11" selected={width1==='11'}>11</option>
                                <option value="12" selected={width1==='12'}>12</option>
                                <option value="13" selected={width1==='13'}>13</option>
                                <option value="14" selected={width1==='14'}>14</option>
                                <option value="15" selected={width1==='15'}>15</option>
                                <option value="16" selected={width1==='16'}>16</option>
                                <option value="17" selected={width1==='17'}>17</option>
                                <option value="18" selected={width1==='18'}>18</option>
                                <option value="19" selected={width1==='18'}>19</option>
                                <option value="20" selected={width1==='20'}>20</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td>Speed:</td>
                        <td>
                            <select name="speed" id="speed">
                                <option value="1000" selected={speed1==='1000'}>Low</option>
                                <option value="750" selected={speed1==='750'}>Medium</option>
                                <option value="500" selected={speed1==='500'}>High</option>
                            </select>
                        </td>
                    </tr>
                </table>

                <div>
                    <button type="button" onClick={()=> { this.resetGameOptions(); this.props.hide();}}>
                        Ok
                    </button>
                    <button type="button" onClick={this.props.hide}>
                        Close
                    </button>
                </div>
            </div>
        </form>;

        return content;
    }
}