import FigureId from '../Figure/FigureId';
import NextFigureSquare from './NextFigureSquare';
import NextFigureLeftCorner from './NextFigureLeftCorner';
import NextFigureLeftStep from './NextFigureLeftStep';
import NextFigureLine from './NextFigureLine';
import NextFigurePyramid from './NextFigurePyramid';
import NextFigureRightCorner from './NextFigureRightCorner';
import NextFigureRightStep from './NextFigureRightStep';

    // implements pattern Factory.
    //
class NextFigureFactory {
    // implements pattern Factory method.
    //
    create(nextFigureId) {

        let nextFigure = null;

        switch (nextFigureId) {
            case FigureId.FigureLeftCorner:

                nextFigure = new NextFigureLeftCorner();
                break;

            case FigureId.FigureLeftStep:

                nextFigure = new NextFigureLeftStep();
                break;

            case FigureId.FigureLine:

                nextFigure = new NextFigureLine();
                break;

            case FigureId.FigurePyramid:

                nextFigure = new NextFigurePyramid();
                break;

            case FigureId.FigureRightCorner:

                nextFigure = new NextFigureRightCorner();
                break;

            case FigureId.FigureRightStep:

                nextFigure = new NextFigureRightStep();
                break;

            case FigureId.FigureSquare:

                nextFigure = new NextFigureSquare();
                break;

            default:
                console.error(`NextFigureFactory.create(nextFigureId): wrong nextFigureId ${nextFigureId}`);
                break;
        }
        return nextFigure;
    }
}
export default NextFigureFactory;
