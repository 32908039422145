import React, { Component } from 'react';
import { SquareFactory } from './square.js';
import { FigureAutoMovingDownControl } from './Control/FigureAutoMovingDownControl';
import { FigureMovingByKeyboardControl } from './Control/FigureMovingByKeyboardControl';
import { FigureFactory } from './Figure/FigureFactory';
import { GameState } from './gameState';
import RemoveFilledLines from './RemoveLines/RemoveFilledLines';
import NextFigure from './NextFigure/NextFigure';

import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown'

import ScoreTable, { Score } from './scoreTable';
import Options from './Options/options';
import FigureColors from './Figure/FigureColors.js';

export default class GameField extends Component {

    constructor(props) {

        super(props);

        this.gameFinishFlag = false;

        this.getRow = this.getRow.bind(this);
        this.startNewGame = this.startNewGame.bind(this);
        this.updateGameState = this.updateGameState.bind(this);
        this.setGameFinishState = this.setGameFinishState.bind(this);

        this.gameState = new GameState();
        this.figureFactory = new FigureFactory();
        this.score = new Score();

        this.state =
        {
            gameField: this.gameState.getMatrix(),
            currentFigureId: this.figureFactory.getRandomFigureId(),
            nextFigureId: this.figureFactory.getRandomFigureId(),
            showOptions: false
        }

        this.autoMoving = null;
        this.keyControl = null;
    }

    dispose() {

        this.autoMoving = null;
        this.keyControl = null;

        this.unsubscribeToKeyEvent();
    }

    init() {

        this.autoMoving = new FigureAutoMovingDownControl();
        this.keyControl = new FigureMovingByKeyboardControl(this.autoMoving);

        this.subscribeToKeyEvent();
    }

    startNewGame() {

        this.dispose();
        this.init();
        this.gameFinishFlag = false;

        //
        this.gameState.init();

        this.setState({            
            gameField: this.gameState.getMatrix()
        });

        //
        this.score.reset();

        this.addNewFigureToGameField();
    }

    keyboardListener = (event) => {

        if (!this.gameFinishFlag) { 

            this.keyControl.keyDown(event.key); 
        }
    }

    subscribeToKeyEvent() {

        window.document.addEventListener("keydown", this.keyboardListener);
        
    }

    unsubscribeToKeyEvent() {

        window.document.removeEventListener("keydown",  this.keyboardListener);
    }


    setGameFinishState() {
        
        this.gameFinishFlag = true;
        this.gameFinish();
    }

    gameFinish()
    {
        document.getElementById("dropdown-item-button").disabled = false;    
        document.getElementById("newGameMenuItem").disabled = false;
        document.getElementById("optionsMenuItem").disabled = false;
    }

    addNewFigureToGameField() {

        if (this.gameFinishFlag === true) {

            this.gameFinish();
            
            return;
        }

        this.setState({
            currentFigureId: this.state.nextFigureId,
            nextFigureId: this.figureFactory.getRandomFigureId()
        });

        let figure =
            this.figureFactory.createFigureFromFigureId(this.state.currentFigureId,
                this.gameState, this.setGameFinishState, this.updateGameState);


        const figureLandedEvent = () => {

            let removeLines = new RemoveFilledLines(this.gameState, this.updateGameState, this.score);
            const waitingTime = removeLines.remove();

            this.checkForUserVictory();

            setTimeout(()=>this.addNewFigureToGameField(), waitingTime);
        };

        this.autoMoving.setFigure(figure, figureLandedEvent);
        this.keyControl.setFigure(figure);
    }

    checkForUserVictory() {

        if (this.gameState.array.every(square => square.color === FigureColors.White)) {

            alert("Victory!");

            this.setGameFinishState();
        }
        
    }

    updateGameState(matrix) {

        this.setState({
            gameField: matrix
        });
    }
        
    getRow(line) {

        return (<tr key={Math.random()}>
            {line.map(x => <td key={x.cellId}><SquareFactory key={x.cellId} color={x.color} cellId={x.cellId} /></td>)}
        </tr>);
    }

    optionsOn = () => {

        this.setState({
            showOptions: true
        });

    }

    optionsOff = () => {

        this.setState({
            showOptions: false
        });

    }

    render() {
        
        const map = Array.prototype.map;

        const newGame =() => {

            document.getElementById("dropdown-item-button").disabled = true;
            document.getElementById("newGameMenuItem").disabled = true;
            document.getElementById("optionsMenuItem").disabled = true;

            document.getElementById("gameField").focus();
            this.startNewGame();
        }

        let gameField =      
        <div>
            <DropdownButton id="dropdown-item-button" title="Menu">
                <Dropdown.Item as="button" id="newGameMenuItem" onClick={newGame}>New game</Dropdown.Item>
                <Dropdown.Item as="button" id="optionsMenuItem" onClick={this.optionsOn}>Options...</Dropdown.Item>
            </DropdownButton>

            <p/>            
            
            <table border="2">
                <tbody id="gameField">
                    <td>
                        {map.call(this.state.gameField, line => this.getRow(line))}
                    </td>
                    <td>
                        <NextFigure nextFigureId={this.state.nextFigureId} key={this.state.nextFigureId} />
                        <ScoreTable counter={this.score.getScore()}/>                                            
                    </td>                
                </tbody>
            </table>
            
        <div>
            {this.state.showOptions ? <Options hide={this.optionsOff}/> : null}
        </div>

        </div>;
            
    
        return gameField;
    }
}   