// id codes of figures.
//
const FigureId = {

    None: 0,
    FigureLeftCorner: 1,
    FigureLeftStep: 2,
    FigureLine: 3,
    FigurePyramid: 4,
    FigureRightCorner: 5,
    FigureRightStep: 6,
    FigureSquare: 7

}
Object.freeze(FigureId);
export default FigureId;

