import * as Settings from '../settings';
import FigureColors from './FigureColors';
import FigureId from './FigureId';
import Figure from './Figure';

const FigureRightStepState = {

    Vertical: 0,
    Horizontal: 1
}
Object.freeze(FigureRightStepState);

    //   *
    // * *
    // *
    //
export default class FigureRightStep extends Figure {
    constructor(gameState, finishHandler, updateStateHandler) {
        super(gameState, finishHandler, updateStateHandler);

        this.id = FigureId.FigureRightStep;
        this.colorOfFigure = FigureColors.Yellow;
        this.currentState = FigureRightStepState.Vertical;

        this.initFigure();
    }

    //
    initFigure() {
        const x = Settings.Width.getWidth() / 2;
        const y = 1;

        this.positionOfFigure = this.getVertical(x, y);
        this.currentState = FigureRightStepState.Vertical;

        this.checkForGameFinish();
    }

    //
    getVertical(x, y) {
        let position = [];

        position.push(this.createElement(x, y - 1));
        position.push(this.createElement(x, y));
        position.push(this.createElement(x - 1, y));
        position.push(this.createElement(x - 1, y + 1));

        return position;
    }

    //
    getHorizontal(x, y) {
        let position = [];

        position.push(this.createElement(x - 1, y - 2));
        position.push(this.createElement(x, y - 2));
        position.push(this.createElement(x, y - 1));
        position.push(this.createElement(x + 1, y - 1));

        return position;
    }

    // changes a state code of figure when rotation is being made.
    // implements pattern Template method.
    //
    changeCurrentState() {
        switch (this.currentState) {
            case FigureRightStepState.Vertical:

                this.currentState = FigureRightStepState.Horizontal;
                break;

            case FigureRightStepState.Horizontal:

                this.currentState = FigureRightStepState.Vertical;
                break;

            default: break;
        }
    }

    // get new figure position for possible rotation.
    // implements pattern Template method.
    //
    getFigureCandidate() {
        let figureCandidate = [];

        const x = this.positionOfFigure[3].x;
        const y = this.positionOfFigure[3].y;

        switch (this.currentState) {
            case FigureRightStepState.Vertical:

                figureCandidate = this.getHorizontal(x, y);
                break;

            case FigureRightStepState.Horizontal:

                figureCandidate = this.getVertical(x, y);
                break;

            default: break;
        }

        return figureCandidate;
    }
}
