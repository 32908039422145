import * as Settings from '../settings';
import FigureColors from './FigureColors';
import FigureId from './FigureId';
import Figure from './Figure';    

//  * *
//  * *
//
export default class FigureSquare extends Figure {
    //
    constructor(gameState, finishHandler, updateStateHandler) {
        super(gameState, finishHandler, updateStateHandler);

        this.id = FigureId.FigureSquare;
        this.colorOfFigure = FigureColors.Blue;

        this.initFigure();
    }

    //
    initFigure() {
        const x = Settings.Width.getWidth() / 2 - 1;
        const y = 0;

        let position = [];
        position.push(this.createElement(x, y));
        position.push(this.createElement(x + 1, y));
        position.push(this.createElement(x, y + 1));
        position.push(this.createElement(x + 1, y + 1));

        this.positionOfFigure = position;
        this.checkForGameFinish();
    }

    //
    changeCurrentState() {
    }

    // returns itself.
    //
    getFigureCandidate() {
        return this.positionOfFigure;
    }
}

