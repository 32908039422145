import React, { Component } from 'react';
import { SquareSize } from './settings';

export class Square extends Component {

    constructor(props) {

        super(props);

        this.cellId = props.cellId;
    }

    render() {

        let bg = `radial-gradient(white, ${this.props.color})`;

        const _style = {

            margin: -3,
            padding: -3,

            width: SquareSize,
            height: SquareSize,

            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "black",

            borderRadius: "20px",

            background: bg
        };

        return <div style={_style}></div>
    }
}

class WhiteSquare extends Square {

    constructor(props) {

        super(props);
        this.cellId = props.cellId;
    }

    render() {

        const _style = {

            margin: -3,
            padding: -3,

            width: SquareSize + 2,
            height: SquareSize + 2,

            border: 0,

            backgroundColor: "white"
        };

        return <div style={_style}></div>
    }
}

export class SquareFactory extends Component {

    render() {
        
        switch(this.props.color) {
    
        case "white":
            return <WhiteSquare  cellId={this.props.cellId}></WhiteSquare>;
        
        default:
            return <Square color={this.props.color} cellId={this.props.cellId}></Square>;
    }}
}
