import FigureId from '../Figure/FigureId';
import FigureColors from '../Figure/FigureColors';

export default class NextFigurePyramid {
    constructor() {

        this.id = FigureId.FigurePyramid;
        this.color = FigureColors.Cyan;
    }

    // implements pattern Template method.
    //
    getPositionOfFigure() {

        let item1 = { x: 1, y: 2 };
        let item2 = { x: 2, y: 2 };
        let item3 = { x: 3, y: 2 };
        let item4 = { x: 2, y: 3 };

        return [item1, item2, item3, item4];
    }

}


