import * as Settings from '../settings';
import FigureColors from './FigureColors';
import FigureId from './FigureId';
import Figure from './Figure';

const FigureLineState = {

    Vertical: 0,
    Horizontal: 1
}
Object.freeze(FigureLineState);

    export default class FigureLine extends Figure
    {
        // ****
        //
        constructor(gameState, finishHandler, updateStateHandler) {
            super(gameState, finishHandler, updateStateHandler);

            this.id = FigureId.FigureLine;
            this.colorOfFigure = FigureColors.Red;
            this.currentState = FigureLineState.Horizontal;

            this.initFigure();
        }

        //
        initFigure()
        {
            const x = Settings.Width.getWidth() / 2;
            const y = 1;

            this.positionOfFigure = this.getHorizontalFigure(x, y);
            this.currentState = FigureLineState.Horizontal;

            this.checkForGameFinish();
        }

        //
        getHorizontalFigure(x, y) {
            let position = [];

            position.push(this.createElement(x - 2, y - 1));
            position.push(this.createElement(x - 1, y - 1));
            position.push(this.createElement(x, y - 1));
            position.push(this.createElement(x + 1, y - 1));

            return position;
        }

        //
        getVericalFigure(x, y) {
            let position = [];

            position.push(this.createElement(x - 1, y - 2));
            position.push(this.createElement(x - 1, y - 1));
            position.push(this.createElement(x - 1, y));
            position.push(this.createElement(x - 1, y + 1));

            return position;
        }

        // changes a state code of figure when rotation is being made.
        // implements pattern Template method.
        //
        changeCurrentState()
        {
            switch (this.currentState)
            {
                case FigureLineState.Horizontal:

                    this.currentState = FigureLineState.Vertical;
                    break;

                case FigureLineState.Vertical:

                    this.currentState = FigureLineState.Horizontal;
                    break;

                default: break;
            }
        }

        // get new figure position for possible rotation.
        // implements pattern Template method.
        //
        getFigureCandidate()
        {
            let figureCandidate = [];

            const x = this.positionOfFigure[3].x;
            const y = this.positionOfFigure[3].y;

            switch (this.currentState)
            {
                case FigureLineState.Horizontal:

                    figureCandidate = 
                        this.getVericalFigure(x, y);
                    break;

                case FigureLineState.Vertical:

                    figureCandidate = 
                        this.getHorizontalFigure(x, y);
                    break;

                default: break;
            }

            return figureCandidate;
        }
    }
