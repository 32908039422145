import * as Settings from './settings';
import FigureColors from './Figure/FigureColors';

export class GameState
{
    constructor()
    {
        this.array = [];

        this.init();
    }

    init() {

        this.array = [];

        for (let y = 0; y < Settings.Height.getHeight(); y++) {
            for (let x = 0; x < Settings.Width.getWidth(); x++) {

                let item = {
                    x: x,
                    y: y,
                    color: FigureColors.White,
                    cellId: `${parseInt(x)}.${parseInt(y)}`,
                };

                this.array.push(item);
            }
        }
    }

    setElement(x, y, _color, _cellId) {

        let item1 = {
            x: x,
            y: y,
            color: _color,
            cellId: `${parseInt(x)}.${parseInt(y)}`,
        };

        const index = this.array.findIndex(item => item.cellId === _cellId);

        if (index === -1) {

            throw new Error("GameState.setElement(): no element with cellId=" + _cellId);
        }

        this.array[index] = item1;
    }

    getElement(_cellId) {

        const index = this.array.findIndex(element => element.cellId === _cellId);

        if (index === -1) {
            throw new Error("GameState.getElement(): no element with cellId=" + _cellId);
        }

        return this.array[index];
    }

    getMatrix() {

        let matrix = [];

        for (let y = 0; y < Settings.Height.getHeight(); y++) {

            matrix[y] = [];

            for (let x = 0; x < Settings.Width.getWidth(); x++) {

                const _cellId = `${parseInt(x)}.${parseInt(y)}`;
                const item = this.array.find(item => item.cellId === _cellId);

                matrix[y].push(item);
            }
        }

        return matrix;
    }

}
