const FigureColors = {

    None: "none",
    White: "white",
    Blue: "blue",
    Red: "red",
    Brown: "brown",
    Green: "green",
    Orange: "orange",
    Yellow: "yellow",
    Cyan: "cyan"
}
Object.freeze(FigureColors);
export default FigureColors;

