import * as Settings from '../settings';
import FigureColors from '../Figure/FigureColors';

export default class RemoveFilledLines {

    constructor(gameState, updateGameField, scoreState) {

        this.gameState = gameState;
        this.updateGameField = updateGameField;
        this.scoreState = scoreState;

        this.linesToRemove = [];
    }

    remove() {

        if (this.doesRemovalNeeded() === false) {
            return 0;
        }

        this.fillLinesToRemoveArray();
        this.markLinesToRemove();
        this.updateGameField(this.gameState.getMatrix());
               
        setTimeout(()=> {
            this.processRemoval();
            this.updateGameField(this.gameState.getMatrix());
        }, 1000);

        return 1000;
    }

    //
    doesRemovalNeeded() {
        let height = Settings.Height.getHeight();

        for (let lineNumber = height - 1; lineNumber >= 0; lineNumber--) {
            if (this.doesRemovalLineNeeded(lineNumber) === true) {
                return true;
            }
        }

        return false;
    }

    doesRemovalLineNeeded(lineNumber) {
        let width = Settings.Width.getWidth();

        for (let x = 0; x < width; x++) {
            let cellId = `${x}.${lineNumber}`;

            if (this.gameState.getElement(cellId).color === FigureColors.White) {
                return false;
            }
        }

        return true;
    }

    //
    fillLinesToRemoveArray() {

        let height = Settings.Height.getHeight();

        for (let lineNumber = height - 1; lineNumber >= 0; lineNumber--) {
            if (this.doesRemovalLineNeeded(lineNumber) === true) {
                
                this.linesToRemove.push(lineNumber);
            }
        }
    }

    //
    processRemoval() {
        let height = Settings.Height.getHeight();

        for (let lineNumber = height - 1; lineNumber >= 0; lineNumber--) {
            if (this.doesRemovalLineNeeded(lineNumber) === true) {
                this.processOneLineRemoval(lineNumber);

                // reset the loop.
                //
                lineNumber = height;
            }
        }
    }

    //
    markLinesToRemove() {

        let width = Settings.Width.getWidth();

        for(const lineNumber of this.linesToRemove) {

            for (let x = 0; x < width; x++) {

                let cellId = `${x}.${lineNumber}`;

                this.gameState.setElement(x, lineNumber, 'BlueViolet', cellId);
            }
        }
    }

    //
    processOneLineRemoval(lineNumber) {
        //
        this.scoreState.increment(1);

        //
        let width = Settings.Width.getWidth();

        for (let y = lineNumber; y >= 1; y--) {
            for (let x = 0; x < width; x++) {
                let element = this.gameState.getElement(`${x}.${y - 1}`);
                let cellId = `${x}.${y}`;

                this.gameState.setElement(x, y, element.color, cellId);
            }
        }

        this.clearUpperLine();
    }

    //
    clearUpperLine() {
        let width = Settings.Width.getWidth();

        let upperLineIndex = 0;

        for (let x = 0; x < width; x++) {
            let cellId = `${x}.${upperLineIndex}`;

            this.gameState.setElement(x, upperLineIndex, FigureColors.White, cellId);
        }
    }
}
